<template>
  <section class="content box box-body">
    <div v-if="loading === 'after'" class="row">
      <div class="col-sm-12">
        <div class="box box-default box-solid">
          <div class="box-body table-responsive">
            <grid
              :content="incompleteTasks"
              :name="gridName"
              :columns="gridColumns"
              :actions="gridActions"
              :searchable="false"
              :server-side="false"
              @show="actionShow"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import Grid from '../share/grid/Grid'
  import { mapState } from 'vuex'

  export default {
    name: 'IncompleteTaskListingComponent',
    components: { Grid },
    data () {
      return {
        events: {},
        gridActions: [],
        gridColumns: [],
        gridDataSourceMethod: 'get',
        gridDataSource: {},
        gridName: 'grid_incomplete_task',
        service: this.$route.meta.acl.service,
        tabsData: []
      }
    },
    computed: {
      ...mapState({
        incompleteTasks: state => state.incompleteTasks.tasks,
        loading: state => state.incompleteTasks.loading
      })
    },
    created () {
      this.getGridDataSource(this.service)
      this.getGridActions()
      this.getGridColumns()
    },
    mounted () {
    },
    methods: {
      getGridActions () {
        this.gridActions = [
          {
            id: 0,
            event: 'show',
            title: 'Szczegóły',
            icon: 'eye',
            params: ['taskId', 'parentId', 'client', 'workflow'],
            acl: {
              service: this.service,
              action: ['incomplete_tasks', 'get_incomplete_tasks'],
              allowOneOfServices: true,
              allowOneOfActions: true
            }
          }
        ]
      },
      getGridDataSource (service) {
        this.gridDataSource = {
          service: 'intgen',
          url: `/tasks/incomplete`
        }
      },
      getGridColumns () {
        this.gridColumns = [
          {
            id: 0,
            data: 'number',
            title: 'NR CRLS',
            searchable: false
          }
        ]
      },
      actionShow (id, params) {
        if (params) {
          let workflowMap = {
            'App\\Entity\\MainVehicleTask': 'main_vehicle',
            'App\\Entity\\MainPropertyTask': 'main_property',
            'App\\Entity\\MainPersonTask': 'main_person'
          }
          if (params.hasOwnProperty('client')) {
            return this.$router.push({
              name: `intbls_${params.client}_${workflowMap[params.workflow]}_appraisal_task_details`,
              params: { id: params.parentId, appraisalTaskId: id }
            })
          } else {
            return this.$router.push({
              name: `intgen_${workflowMap[params.workflow]}_appraisal_task_details`,
              params: { id: params.parentId, appraisalTaskId: id }
            })
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
